<template>
  <div>
    <v-navigation-drawer
          v-model="drawerRight"
          app
          clipped-left="true"
          dark
          color="#eee"
          v-bind:width="172"
          src="../assets/bg-2.jpg"
        >
        <v-list
            class="dense nav"
            >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title" color="ciscowhite">
                    <b>ADR Operations Menu</b>
                </v-list-item-title>
                <v-list-item-subtitle color="ciscowhite">
                    
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

        <v-divider/>
            <v-list-item-group v-for="(nav_item, i) in nav_items"
                :key="i" color="ciscowhite">
              <v-list-item density="compact"
                  :class="nav_item.path == $route.path ? 'highlighted' : ''"
                  v-on:click.stop="navigate(nav_item.path)"
                  :prepend-icon="nav_item.icon" :title="nav_item.text"
              >
              </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
    <v-app-bar class="pb-0 mb-0"
        app
        clipped-right
        color="#0ab7d7"
        density="compact"
        >
         <template v-slot:prepend>
          <v-app-bar-nav-icon class="mb-2" @click="toggleNav"></v-app-bar-nav-icon>
        </template>
        <img class="pa-md-4 mt-1 mx-lg-auto" src="../assets/TTEC_logo.png" alt="TTEC ADR 3.5.8" height="55px" width="75px">

        <v-tooltip
          v-model="show"
          location="top"
        >
          <template v-slot:activator="{ props }">
            <v-btn
            class="text-xlarge text-bold ttec-text-gap ml-5 mt-1"
              v-bind="props"
            >
            ADR Portal
            </v-btn>
          </template>
          <span>ADR VERSION INFORMATION - v3.5.8 - build 139</span>
        </v-tooltip>

        
        <v-spacer />
        <v-btn class="ciscoblue pt-0 pb-0 rounded-pill elevation-2"  @click="logout">{{ getLoginUser() }} | Log out</v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data: () => ({
    drawer: null,
    drawerRight: false,
    nav_items: [],
    show: false,
  }),
  methods: {
    navigate (path) {
      this.$nextTick(() => this.$router.push({ path: path }))
      this.toggleNav()
    },
    async logout () {
      if (this.$store.getters.getSsoLogin == true) {
        await this.$msal.logoutRedirect({
          postLogoutRedirectUri: window.location.origin, // Or specify another URI as needed
          account: this.$store.getters.getAccount
        });
      }

      localStorage.clear()
      sessionStorage.clear()
      this.$store.commit('setSsoLogin', false);
      this.$store.dispatch('logout');
      // this.$router.replace({ path: '/' });
      this.$nextTick(() => this.$router.push({ path: "/" }))
    },
    isAdminRole () {
      return this.$store.getters.isAdminRole
    },
    getLoginUser () {
      return this.$store.getters.getLoginUserName
    },
    toggleNav () {
      this.$store.dispatch('updateNavBar', !this.drawerRight)
      this.drawerRight = !this.drawerRight
    }
  },
  mounted () {
    this.drawerRight = this.$store.getters.getDrawerLeft

    if (this.isAdminRole()) {
      this.nav_items = [
        { text: 'Administration', icon: 'mdi-file-document-edit-outline', path: '/admin' },
        { text: 'Devices', icon: 'mdi-server', path: '/devices' },
        { text: 'Set Maintenance', icon: 'mdi-wrench', path: '/setupmaint' },
        { text: 'Maint. Overview', icon: 'mdi-laptop', path: '/maintenance' },
        { text: 'Dashboard', icon: 'mdi-chart-bar', path: '/dashboard' },
        { text: 'GCP Status', icon: 'mdi-rss', path: '/gcpstatus' },
        { text: 'Webex Status', icon: 'mdi-cloud', path: '/webexstatus' },
        { text: 'Datadog Hosts', icon: 'mdi-server-network', path: '/ddhost' }, 
        { text: 'Datadog Monitors', icon: 'mdi-monitor', path: '/ddmonitor' },
        { text: 'Datadog Downtime', icon: 'mdi-power', path: '/downtime' },
        { text: 'Datadog Events', icon: 'mdi-access-point', path: '/ddevent' },
        { text: 'About', icon: 'mdi-information-outline', path: '/about' }
      ]
    } else {
      this.nav_items = [
        { text: 'Devices', icon: 'mdi-server', path: '/devices' },
        { text: 'Set Maintenance', icon: 'mdi-wrench', path: '/setupmaint' },
        { text: 'Maint. Overview', icon: 'mdi-laptop', path: '/maintenance' },
        { text: 'Dashboard', icon: 'mdi-chart-bar', path: '/dashboard' },
        { text: 'GCP Status', icon: 'mdi-rss', path: '/gcpstatus' },
        { text: 'Webex Status', icon: 'mdi-cloud', path: '/webexstatus' },
        { text: 'Datadog Hosts', icon: 'mdi-server-network', path: '/ddhost' },
        { text: 'Datadog Monitors', icon: 'mdi-monitor', path: '/ddmonitor' },
        { text: 'Datadog Downtime', icon: 'mdi-power', path: '/downtime' },
        { text: 'Datadog Events', icon: 'mdi-access-point', path: '/ddevent' },
        { text: 'About', icon: 'mdi-information-outline', path: '/about' }
      ]
    }
  }
}
</script>
<style scoped>
.highlighted {
  border: 2px dashed orange;
}

.v-tooltip__content {
  font-size: 8px !important;
  opacity: 1 !important;
  padding: 2px !important;
}

.ttec-text-gap {
  letter-spacing: 1px !important;
  color: #444444 !important;
  font-weight: bold;
  font-size: 18px;
}

.prepend-icon {
    color: red;
} 

.v-list-itme__icon--append .v-icon { 
    color: purple;
}
</style>
