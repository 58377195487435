import { createStore } from 'vuex'
import auth from './auth/index'
import { isValidJwt, EventBus} from '@/utils'
import { authenticate, authenticate_sso, register, getdbdata,
  getDeviceData, getWxcceDeviceData, getHcsFedDeviceData, getHcsDeviceData,
  getWxFedDeviceData, getPremiseDeviceData, getDeviceCredList, getCustConnInfo,
  getWxCustConnInfo, getHcsFedCustConnInfo, getCustomerData, getDevTypeData,
  getAuditData, getIssueData, getAuditPerfData, getAccessLogData,
  getAccesslogPerfData, getAllDeviceData,
  getAllCustConnInfo, getCustDevices, getCredData,
  addCustomer, saveCustomer, deleteCustomer, saveCustCC,
  addDeviceType, saveDeviceType, deleteDeviceType,
  addDevCred, saveDevCred, deleteDevCred, addDevice,
  saveDevice, deleteDevice, uploadClientDevices, 
  setCredAccessLog, addDevInfoIssueRpt } from '@/api'



const store =  createStore({
  state: {
    userData: {},
    jwt: '',
    componentSelect: [],
    drawerLeft: false,
    loginUserName: '',
    loginUid: '',
    isAminUser: false,
    ssoLogin: false,
    account: undefined
  },
  getters: {
    // reusable data accessors
    isAuthenticated (state) {
      return isValidJwt(state.jwt)
    },
    getJwtToken (state) {
      return state.jwt
    },
    getRefreshToken () {
      return state.refresh
    },
    getLoginUser (state) {
      return state.loginUserName
    },
    getLoginUserName (state) {
      return state.loginUserName
    },
    getLoginUid (state) {
      return state.loginUid
    },
    isAdminRole (state) {
      return state.isAminUser
    },
    getDrawerLeft (state) {
      return state.drawerLeft
    },
    getSsoLogin (state) {
      return state.ssoLogin
    },
    getAccount (state) {
      return state.account
    }
  },
  mutations: {
    setUserData (state, payload) {
      // console.log('setUserData payload = ', payload)
      state.userData = payload.userData
      // console.log('store userDate = ', state.userData)
    },
    setJwtToken (state, payload) {
      localStorage.token = payload.jwt
      state.jwt = payload.jwt
      // console.log('set JWT state = ' + state.jwt)
    },
    unsetJwtToken (state) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      state.jwt = ''
      state.refresh = ''
      // console.log('JWT state = ' + state.jwt)
    },
    setLoginUserName (state, name) {
      state.loginUserName = name
    },
    setLoginUid (state, name) {
      state.loginUid = name
    },
    setIsAdminRole (state, val) {
      state.isAminUser = val
    },
    setDrawerLeft (state, val) {
      state.drawerLeft = val
    },
    setSsoLogin (state, val) {
      state.ssoLogin = val
    },
    setAccount (state, val) {
      state.account = val
    }
  },
  actions: {
    initializeStore(context) {
      // console.log("Initializing store . . .")
      const savedUser = localStorage.getItem('authUser');
      let response = JSON.parse(savedUser)
      if (response && response.data)
      if (response.data.authenticated === true) {
        // commit('setAuthUser', JSON.parse(savedUser));
        context.commit('setJwtToken', { jwt: response.data.token })
        context.commit('setLoginUid', response.data.username)
        context.commit('setLoginUserName', response.data.displayName)
        context.commit('setIsAdminRole', response.data.isAdrAdmin)
        EventBus.emit('Authenticated_user', response.data.authenticated)
      } else {
        EventBus.emit('failedAuthentication', response.data.err_msg)
      }
    },
    loginSSO (context, response) {
      context.commit('setSsoLogin', true)
      context.commit('setAccount', response.accountinfo)
      let roles = response.accountinfo.idTokenClaims.roles
      let uname = ''
      if (response.accountinfo.username.indexOf("@") > -1) {
        const tokens = response.accountinfo.username.split("@")
        uname = tokens[0]
      }
      else {
        uname = response.accountinfo.username
      }
      context.commit('setUserData', { usrid: uname, password: 'xxxxx' })
      let sso_data = {authenticated: true, usrid: response.accountinfo.name, username: uname, roles: roles}
      return authenticate_sso(sso_data)
        .then(function (response) {
          if (response.data.authenticated === true) {
            localStorage.setItem('authUser', JSON.stringify(response));
            context.commit('setJwtToken', { jwt: response.data.token })
            context.commit('setLoginUid', response.data.username)
            context.commit('setLoginUserName', response.data.displayName)
            context.commit('setIsAdminRole', response.data.isAdrAdmin)
            EventBus.emit('Authenticated_user', response.data.authenticated)
          } else {
            EventBus.emit('failedAuthentication', response.data.err_msg)
          }
        })
        .catch(error => {
          EventBus.emit('failedAuthentication', error)
        })
    },
    login (context, userData) {
      
      context.commit('setUserData', { userData })
      
      return authenticate(userData)
        .then(function (response) {
          // alert('Got response from promise.')
          if (response.data.authenticated === true) {
            // alert('Found Auth as true')
            context.commit('setJwtToken', { jwt: response.data.token })
            context.commit('setLoginUid', response.data.username)
            context.commit('setLoginUserName', response.data.displayName)
            context.commit('setIsAdminRole', response.data.isAdrAdmin)
            // alert('after login successfully and emitter msg => Authenticated_user')

            
            EventBus.emit('Authenticated_user', response.data.authenticated)
            // alert('after emit')
          } else {
            // console.log('In store failedAuth = ' + response.data.err_msg)
            // alert('failedAuthentication event fired!! with msg => ' + response.data.err_msg)
            EventBus.emit('failedAuthentication', response.data.err_msg)
          }
        })
        .catch(error => {
          // console.log('Error Authenticating: ', error)
          EventBus.emit('failedAuthentication', error)
        })
    },
    refresh (context, tokens) {
      context.commit('setJwtToken', { jwt: tokens.access_token })
      localStorage.refreshToken = tokens.refresh_token
      state.refresh = tokens.refresh_token
      localStorage.token = tokens.access_token
      state.jwt = tokens.access_token
    },
    logout (context) {
      context.commit('unsetJwtToken')
      
      // EventBus.emit('Logout')
    },
    updateNavBar (context, val) {
      context.commit('setDrawerLeft', val)
    },
    register (context, userData) {
      context.commit('setUserData', { userData })
      // console.log(userData)
      return register(userData)
        .then(function (response) {
          // console.log('Got response from promise.')
          if (response.data.status.indexOf('error') !== -1) {
            // console.log('Found error in status return')
            EventBus.emit('failedRegistering', response.data.err_msg)
          } else {
            // console.log('Return Status is good')
            setTimeout(() => {
              // this.$router.push('/')
              EventBus.emit('Logout', 'success')
            }, 5000)
          }
        })
        .catch(error => {
          // console.log('Error Registering: ', error)
          EventBus.emit('failedRegistering: ', error)
        })
    },
    getdbdata (context, dbname) {
      return getdbdata(dbname, context.state.jwt)
        .then(function (response) {
          EventBus.emit(String(dbname) + '_dbreturn', response.data)
        })
    },
    getDeviceData (context, query) {
      return getDeviceData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('device_return', response.data)
        })
    },
    getCustDevices (context, query) {
      return getCustDevices(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('cusDevices_return', response.data)
        })
    },
    getAllDeviceData (context, query) {
      return getAllDeviceData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('alldevice_return', response.data)
        })
    },
    getWxcceDeviceData (context, query) {
      return getWxcceDeviceData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('wxccedevice_return', response.data)
        })
    },
    getHcsDeviceData (context, query) {
      return getHcsDeviceData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('hcsdevice_return', response.data)
        })
    },
    getHcsFedDeviceData (context, query) {
      return getHcsFedDeviceData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('hcsfeddevice_return', response.data)
        })
    },
    getWxFedDeviceData (context, query) {
      return getWxFedDeviceData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('wxfeddevice_return', response.data)
        })
    },
    getPremiseDeviceData (context, query) {
      return getPremiseDeviceData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('premisedevice_return', response.data)
        })
    },
    getDeviceCredList (context, query) {
      return getDeviceCredList(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('device_cred_return', response.data)
        })
    },
    getCustConnInfo (context, query) {
      return getCustConnInfo(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('CC_return', response.data)
        })
    },
    getAllCustConnInfo (context, query) {
      return getAllCustConnInfo(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('allCC_return', response.data)
        })
    },
    getWxCustConnInfo (context, query) {
      return getWxCustConnInfo(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('wxCC_return', response.data)
        })
    },
    getHcsFedCustConnInfo (context, query) {
      return getHcsFedCustConnInfo(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('hcsFedCC_return', response.data)
        })
    },
    getCustomerData (context, query) {
      return getCustomerData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('customers_return', response.data)
        })
    },
    getDevTypeData (context, query) {
      return getDevTypeData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('devType_return', response.data)
        })
    },
    getCredData (context, query) {
      // alert('getCredData is called in store api')
      return getCredData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('credentials_return', response.data)
        })
    },
    getAuditData (context, query) {
      return getAuditData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('audit_return', response.data)
        })
    },
    getIssueData (context, query) {
      return getIssueData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('issue_return', response.data)
        })
    },
    getAuditPerfData (context, query) {
      return getAuditPerfData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('auditperf_return', response.data)
        })
    },
    getAccessLogData (context, query) {
      return getAccessLogData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('accesslog_return', response.data)
        })
    },
    getAccesslogPerfData (context, query) {
      return getAccesslogPerfData(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('accesslogperf_return', response.data)
        })
    },
    addCustomer (context, query) {
      return addCustomer(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('addCustomer_return', response.data)
        })
    },
    saveCustomer (context, query) {
      return saveCustomer(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('saveCustomer_return', response.data)
        })
    },
    deleteCustomer (context, query) {
      return deleteCustomer(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('deleteCustomer_return', response.data)
        })
    },
    saveCustCC (context, query) {
      return saveCustCC(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('saveCustCC_return', response.data)
        })
    },
    addDeviceType (context, query) {
      return addDeviceType(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('addDeviceType_return', response.data)
        })
    },
    saveDeviceType (context, query) {
      return saveDeviceType(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('saveDeviceType_return', response.data)
        })
    },
    deleteDeviceType (context, query) {
      return deleteDeviceType(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('deleteDeviceType_return', response.data)
        })
    },
    addDevCred (context, query) {
      return addDevCred(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('addDevCred_return', response.data)
        })
    },
    saveDevCred (context, query) {
      return saveDevCred(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('saveDevCred_return', response.data)
        })
    },
    deleteDevCred (context, query) {
      return deleteDevCred(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('deleteDevCred_return', response.data)
        })
    },
    addDevice (context, query) {
      return addDevice(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('addDevice_return', response.data)
        })
    },
    saveDevice (context, query) {
      return saveDevice(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('saveDevice_return', response.data)
        })
    },
    deleteDevice (context, query) {
      return deleteDevice(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('deleteDevice_return', response.data)
        })
    },
    addDevInfoIssueRpt (context, query) {
      return addDevInfoIssueRpt(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('addDevInfoIssueRpt_return', response.data)
        })
    },
    uploadClientDevices (context, query) {
      return uploadClientDevices(query, context.state.jwt)
        .then(function (response) {
          // console.log("Upload Response:", response?.data); // Added logger in console
          EventBus.emit('uploadClientDevices_return', response.data)
        })
    },
    setCredAccessLog (context, query) {
      return setCredAccessLog(query, context.state.jwt)
        .then(function (response) {
          EventBus.emit('setCredAccessLog_return', response.data)
        })
    }
  },
  components: {
    auth
  }
})

store.dispatch('initializeStore');

export default store

