<template>
  <div class="cui">
    <div class="content-fluid">
      <main>
        <header class="header header--tall">
          <div class="header-bar container">
            <div class="header-bar__main">
              <div class="header-menus">
                <ul class="nav nav-tabs">
                  <li class="tab">
                    <a href="#">
                      <div class="tab__heading text-bold" title="Basic">About</div>
                    </a>
                  </li>
                </ul>
                <span class="text-small">TTEC Digital ADR Portal - Powered by Vue 3, Vuetify 3, Flask-RESTX, GCP</span>
                <br><span class="text-xsmall">Version Information: v3.5.8 - build 139</span>
              </div>
            </div>
            <div class="header-toolbar">
              <a href="javascript:;" class="btn btn--icon">
                <span class="icon-search"></span>
              </a>
              <a href="javascript:;" class="btn btn--icon">
                <span class="icon-alert"></span>
              </a>
              <a href="javascript:;" class="btn btn--icon">
                <span class="icon-cog"></span>
              </a>
            </div>
          </div>
        </header>
      </main>
    </div>
  </div>
</template>
