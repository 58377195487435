<template>
  <div id="tadogDowntime" class="bg">
        <div class="text-center">
            <WarningDlg ref="warning" />
            <ConfirmDlg ref="confirm" />
            <br/><br/>
            <div class="cui">
              <v-chip
                  class="ma-2 text-bold text-medium"
                  outlined
                  >
                  Datadog Downtime Management
              </v-chip>
            </div>
            <div>
                  <div mt-3 mb-2 v-if="showAddDowntime">
                      <h5 class="ml-6 text-ttec">Schedule Downtime</h5>
                        <div class="ttec-div-border">
                          <h6 class="text-h6">Step 1 - Choose what to silence</h6>
                           <v-btn-toggle
                              v-model="monitor_selection"
                              color="#EA8000"
                              dense
                              rounded="0"
                              group
                              mb-2
                            >
                              <v-btn dense class="ttec-text" value="By Monitor Name">
                                By Monitor Name
                              </v-btn>
                              <v-btn dense class="ttec-text" value="By Monitor Tags">
                                By Monitor Tags
                              </v-btn>
                            </v-btn-toggle><br/><br/>
                            <v-row class="ml-1">
                              <v-autocomplete
                                v-if="monitor_type == 'Monitor'"
                                density="compact"
                                class="mr-8"
                                v-model="selected_monitor"
                                label="Monitor"
                                :items="monitor_id_array"
                              ></v-autocomplete>
                              <v-autocomplete
                                v-if="monitor_type == 'Monitor tags'"
                                density="compact"
                                class="mr-8"
                                v-model="selected_monitor_tags"
                                label="Monitor tags"
                                :items="monitor_tags_array"
                              ></v-autocomplete>
                            </v-row>
                            
                            <v-autocomplete
                               density="compact"
                               class="mr-5"
                               v-model="selected_group_scope"
                               label="Group scope"
                               :items="scope_array"
                            ></v-autocomplete>
                          <h6 class="text-h6">Step 2 - Schedule</h6>
                             <v-row class="mt-1 mr-3">
                              <v-col cols="2">
                                 <v-btn-toggle
                                  v-model="schedule_type"
                                  color="#EA8000"
                                  dense 
                                  rounded="0"
                                  group
                                >
                                  <v-btn dense class="ttec-text" value="One Time">
                                    One Time
                                  </v-btn>
                                </v-btn-toggle>
                              </v-col>
                              <v-col cols="3">
                                 <span class="ttec_left ttec-text">Start:</span> 
                                 <span v-if="downtime_absstart" class="ttec_right ttec-text"> 
                                   (UTC: {{ downtime_absstart_utc  }}) *
                                 </span>
                                <VueDatePicker v-model="downtime_absstart" ></VueDatePicker>
                              </v-col>
                              <v-col cols="3">
                                  <span class="ttec_left ttec-text">End (Optional):</span> 
                                  <span v-if="downtime_absend" class="ttec_right ttec-text">
                                    (UTC: {{ downtime_absend_utc  }})
                                  </span>
                                  <VueDatePicker  v-model="downtime_absend" ></VueDatePicker>
                              </v-col>
                            </v-row>
                            <v-spacer></v-spacer>
                            <br/>
                          <h6 class="text-h6">Step 3 - Add a message</h6>
                          <v-text-field density="compact" v-model="downtime_msg" label="Enter a short message for the dowmtime" class="mr-5"></v-text-field>
                          <div>
                            <v-btn class="ml-6 text-ttec" @click="resetAddDowntime">Cancel</v-btn>
                            <v-btn :disabled="(selected_monitor === '' && selected_monitor_tags === '') || selected_group_scope === ''  || downtime_absstart_utc === '' || (downtime_absend_utc !== '' && (downtime_absstart >= downtime_absend)) " 
                                class="ml-6 text-ttec" @click="createDowntime">
                              Save
                            </v-btn>
                          </div>
                          <br/>
                        </div>
                      <br/><br/>
                    </div>
            </div>
            <div>
              <v-row no-gutters>
                  <v-text-field
                            class="custom-text-field mb-5 ml-8 mr-9 pt-2 mt-1"
                            v-model="search"
                            append-icon="mdi-magnify"
                            density="compact"
                            single-line
                            placeholder="Search by status, scope, monitor tags, monitor or description"
                            hide-details
                        >
                    </v-text-field>

                    <v-btn :disabled="selectedDowntimes.length == 0"  density="compact" variant="elevated" rounded class="ttec-text ciscoblue mr-4 mt-6 ml-4" @click.stop="termDowntime()">Cancel Selected Downtime</v-btn>
                    <v-btn  variant="outlined" color="#EA8000" density="compact" class="text-ttec mt-6" @click="toggleShowAddDownTime">
                      {{ showAddDowntime ? 'Hide Schedule Downtime' : 'Schedule Downtime' }}
                    </v-btn>
                  </v-row>
                    <v-data-table
                          :headers="headerDowntime"
                          :items="downtimeEntries"
                          :items-per-page="20"
                          item-key="id"
                          item-value="id"
                          v-model="selectedDowntimes"
                          class="elevation-6"
                          :search="search"
                          :loading="loadDowntimeList"
                          loading-text="Loading ..."
                          show-select
                          density="compact"
                          :footer-props="{
                              'items-per-page-options': [10,30, 50, 100, -1],
                                  showFirstLastPage: true,
                                  firstIcon: 'mdi-arrow-collapse-left',
                                  lastIcon: 'mdi-arrow-collapse-right'
                              }"
                          >
                          <template v-slot:[`item.status`]="{ item }">
                              <div v-if="item.status == 'active'" class ="downtime_active" >{{ item.status.toUpperCase() }}</div>
                              <div v-else-if="item.status == 'scheduled'" class ="downtime_scheduled" >{{ item.status.toUpperCase() }}</div>
                              <div v-else-if="item.status == 'cancelded'" class ="downtime_canceled" >{{ item.status.toUpperCase() }}</div>
                              <div v-else class ="downtime_expired">{{ item.status.toUpperCase() }}</div>
                          </template>
                          <template v-slot:[`item.monitor_tags`]="{ item }">
                              <div v-if="item.monitor_tags == '*'" >
                                <v-chip
                                    class="ttec_large_text"
                                    density="compact"
                                    color="#03A9F4"
                                    variant="elevated"
                                  >
                                    {{ item.monitor_tags }}
                                </v-chip>
                              </div>
                              <div v-else>{{ item.monitor_tags }}</div>
                          </template>
                      </v-data-table>
                      <br/> <br/>
            </div>

        
            <v-snackbar
                        v-model="hasSaved"
                        :timeout="3000"
                        attach
                        position="absolute"
                        location="bottom left"
                        >
                        {{ this.snackbar_msg }}
            </v-snackbar>
        </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import WarningDlg from './WarningDlg'
import ConfirmDlg from './ConfirmDlg'
import { useToast } from 'vue-toastification'

export default {
  name: 'DatadogDowntime',
  components: {
    VueDatePicker,
    ConfirmDlg,
    WarningDlg
  },
  data: () => ({
    downtime_absstart: null,
    downtime_absend: null,
    downtime_absstart_utc: '',
    downtime_absend_utc: '',
    downtimeEntries: [],
    headerDowntime: [],
    selectedDowntimes: [],
    loadDowntimeList: false,
    search: '',
    showAddDowntime: false,
    monitor_selection: 'By Monitor Name',
    schedule_type: 'One Time',
    downtime_msg: '',
    addDowntime: true,
    monitor_type: 'Monitor',
    monitor_id_array: [],
    monitor_tags_array: ['All monitors', 'check_status:live', 'check_type:api', 'check_type:api-ssl', 'check_type:browser','ci_execution_rule:blocking','env:verint_mt5','event_id','http_status:4xx','http_status:5xx'],
    scope_array: ['All'],
    selected_monitor: '',
    selected_monitor_tags: '',
    selected_group_scope: ''
  }),
  mounted () {
    var API_URL = 'http://xx.xx.xx.xx:xxxx'
    fetch('/config.json').then(res => res.json()).then(config => {
      API_URL = config['API_URL']
      this.loadDowntimeList = true
      axios.get(`${API_URL}/api/datadog/downtime`, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
        (payload) => {
          this.loadDowntimeList = false
          var retObj = payload.data
          this.downtimeEntries = retObj.data
          this.headerDowntime = [
            { title: 'Status', key: 'status' },
            { title: 'Scope', key: 'scope' },
            { title: 'Recurring', key: 'recurring' },
            { title: 'Monitor Tags', key: 'monitor_tags' },
            { title: 'Monitor', key: 'monitor' },
            { title: 'Description', key: 'description' },
            { title: 'Start', key: 'start',  },
            { title: 'End', key: 'end' }
          ]
          var temp_list = retObj.monitor_list
          temp_list.unshift('All monitors')
          this.monitor_id_array = temp_list

          var temp_list = retObj.host_list
          temp_list.unshift('All')
          this.scope_array = temp_list
        })
    })
  },

  watch: {
    monitor_selection: function (newVal, oldVal) {
      // alert( oldVal + ' -> '  + newVal)
      this.selected_monitor = ''
      this.selected_monitor_tags = ''
      this.selected_group_scope = ''
      if (newVal === 'By Monitor Name') {
        this.monitor_type = 'Monitor'
      } else {
        this.monitor_type = 'Monitor tags'
      }
    },
    downtime_absstart: function (val) {
      var myDate = Date.parse(val)
      var curData = new Date()
      if (myDate < curData) {
        this.downtime_absstart_utc = ''
        this.$refs.warning.open(
          'Warning',
          'Downtime start time must be at least 1 minute later than current time. Please correct start time and try again.'
        )
      } else {
        var momentStartDate = moment(val, 'MM/DD/YYYY HH:mm:ss')
        this.downtime_absstart_utc = momentStartDate.toISOString()
      }
    },
    downtime_absend: function (val) {
      var myEndDate = Date.parse(val)
      this.maint_end = myEndDate / 1000
      var momentEndDate = moment(val, 'MM/DD/YYYY HH:mm:ss')
      this.downtime_absend_utc = momentEndDate.toISOString()
    }
  },

  methods: {
    reset_variables () {
      this.downtime_absstart = null
      this.downtime_absend = null
      this.downtime_absstart_utc = ''
      this.downtime_absend_utc = ''
    },
    getLoginUser () {
      return this.$store.getters.getLoginUser
    },
    toggleShowAddDownTime () {
      this.showAddDowntime = !this.showAddDowntime
    },
    resetAddDowntime () {
      this.selected_monitor = ''
      this.selected_monitor_tags = ''
      this.selected_group_scope = ''
      this.downtime_msg = ''
      this.reset_variables()
    },
    createDowntime () {
      var API_URL = 'http://xx.xx.xx.xx:xxxx'
      fetch('/config.json').then(res => res.json()).then(config => {
        API_URL = config['API_URL']
        var query = {
          'monitor_type': this.monitor_type,
          'downtime_absstart_utc': this.downtime_absstart_utc,
          'downtime_absend_utc': this.downtime_absend_utc,
          'selected_monitor': this.selected_monitor,
          'selected_monitor_tags': this.selected_monitor_tags,
          'selected_group_scope': this.selected_group_scope,
          'downtime_msg': this.downtime_msg + ' [By: ' + this.getLoginUser() + ']',
          'display_timezone': 'America/Chicago'
        }
        axios.post(`${API_URL}/api/datadog/downtime`, query, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
          (payload) => {
            var retObj = payload.data
            var status = retObj.status
            if (status === 'ok') {
              const toast = useToast()
              toast.success('Record had been updated.')
              this.resetAddDowntime()
              this.loadDowntimeList = true
              axios.get(`${API_URL}/api/datadog/downtime`, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
                (payload) => {
                  this.loadDowntimeList = false
                  var retObj = payload.data
                  this.downtimeEntries = retObj.data
                  this.headerDowntime = [
                    { title: 'Status', key: 'status' },
                    { title: 'Scope', key: 'scope' },
                    { title: 'Recurring', key: 'recurring' },
                    { title: 'Monitor Tags', key: 'monitor_tags' },
                    { title: 'Monitor', key: 'monitor' },
                    { title: 'Description', key: 'description' },
                    { title: 'Start', key: 'start',  },
                    { title: 'End', key: 'end' }
                  ]
              })
              this.loadDowntimeList = false
            }
          })
        }
      )
    },
    async termDowntime () {
      if (
        await this.$refs.confirm.open(
          'Confirm',
          'Are you sure to cancel those selected downtime?'
        )
      ) {
          var API_URL = 'http://xx.xx.xx.xx:xxxx'
          fetch('/config.json').then(res => res.json()).then(config => {
            API_URL = config['API_URL']
            var query = {
              'downtime_cancel_list': this.selectedDowntimes
            }
            axios.put(`${API_URL}/api/datadog/downtime`, query, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
              (payload) => {
                var retObj = payload.data
                var status = retObj.status
                // alert(status)
                if (status === 'ok') {
                  const toast = useToast()
                  toast.success('Record had been updated.')
                  this.loadDowntimeList = true
                  axios.get(`${API_URL}/api/datadog/downtime`, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
                    (payload) => {
                      this.loadDowntimeList = false
                      var retObj = payload.data
                      this.downtimeEntries = retObj.data
                      this.headerDowntime = [
                        { title: 'Status', key: 'status' },
                        { title: 'Scope', key: 'scope' },
                        { title: 'Recurring', key: 'recurring' },
                        { title: 'Monitor Tags', key: 'monitor_tags' },
                        { title: 'Monitor', key: 'monitor' },
                        { title: 'Description', key: 'description' },
                        { title: 'Start', key: 'start',  },
                        { title: 'End', key: 'end' }
                      ]
                  })
                  this.loadDowntimeList = false
                }
              })
          })
      }
    }
  }
}
</script>

<style lang="css" scoped>
    .bg {
        width: 100%;
        height: 100%;
        /* position: absolute; */
        top: 0;
        left: 0;
        margin: 0px 0px;
        background: url( '../assets/img/pattern.jpg') repeat;
    }
    .ttec_contentWrapper
    {
        position:relative;
        width:92%;
        height:auto;
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-bottom:5px;
        margin-left: 15px;
    }
    .ttec_content
    {
        padding:1% 1% 2% 2%;
    }
    .ttec_content_selected
    {
        padding:1% 1% 2% 2%;
        border-style: solid;
        border-width: 2px;
        border-color: coral;
    }
    
    .v-data-table :deep(th) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: bold !important;
      background-color: #B0BEC5;
    }
    .v-data-table :deep(td) {
      font-size: 11px !important;
      text-align: left !important;
      padding: 0px;
      font-weight: 300;
    }
    .downtime_active {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #90EE90;
      font-weight:bold;
      background: #90EE90;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .downtime_expired {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #888888;
      font-weight:bold;
      background: #888888;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .downtime_canceled {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #888888;
      font-weight:bold;
      background: #888888;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .downtime_scheduled {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #FFCC00;
      font-weight:bold;
      background: #FFCC00;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .svc_critical {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #F32013;
      font-weight:bold;
      background: #F32013;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .custom-text-field :deep(.v-field__input) { 
      padding-top: 0; 
      padding-block: 0; 
      margin: 0;
      height: 8px !important;
      font-size: 14px;
    }
    .ttec-text {
      text-transform: none !important;
      font-size: 11px !important;
    }
    .text-ttec {
      text-transform: none !important;
      font-size: 11px !important;
      font-weight: bold !important;
    }
    .ttec-div-border {
      border:1px solid #EA8000;
      padding: 5px;
      border-radius: 5px;
      box-shadow: 5px 5px #888888;
      width: 80%; 
    }
    .ttec_left {
      float:left;
      margin-left: 3px;
    }
    .ttec_right {
      float:left;
      margin-left: 6px;
    }

</style>
