<template>
    <div id="datadogMonitor" class="bg">
        <div class="text-center">
            <v-chip
                class="ma-1 text-bold"
                color="success"
                outlined
                >
                <v-icon left>
                    mdi-server-plus
                </v-icon>
                Datadog Manage Monitors
            </v-chip>
        </div>
        <div class="cui">
            <v-row class="bg2">
                <v-col cols="11">
                    <div class="ttec_contentWrapper text-medium text-bold pa-2 mb-0">
                        Datadog Manage Monitors
                     </div>
                    <v-data-table
                        :headers="headerMonitors"
                        :items="monitorEntries"
                        :items-per-page="50"
                        item-key="id"
                        class="elevation-6"
                        density="compact"
                        :footer-props="{
                            'items-per-page-options': [10,20, 50, 100, -1],
                                showFirstLastPage: true,
                                firstIcon: 'mdi-arrow-collapse-left',
                                lastIcon: 'mdi-arrow-collapse-right'
                            }"
                        >
                        <template v-slot:[`item.overall_state`]="{ item }">
                            <div v-if="item.overall_state == 'OK'" class ="monitor_ok" >{{ item.overall_state }}</div>
                            <div v-else-if="item.overall_state == 'Alert'" class ="monitor_alert">{{ item.overall_state }}</div>
                            <div v-else-if="item.overall_state == 'Warn'" class ="monitor_warning">{{ item.overall_state }}</div>
                            <div v-else class ="monitor_warning">{{ item.overall_state }}</div>
                        </template>
                        <template v-slot:[`item.priority`]="{ item }">
                            <div v-if="item.priority != 10" class="ttec_bold_text">P{{ item.priority }}</div>
                            <div v-else class="ttec_bold_text"> - </div>
                        </template>
                        <template v-slot:[`item.silenced`]="{ item }">
                            <div v-if="Object.keys(item.silenced).length === 0"></div>
                            <div v-else-if="(item.silenced).hasOwnProperty('*')">
                               <v-chip
                                density="compact"
                                variant="elevated"
                                append-icon="mdi-infinity"
                                color="orange"
                                prepend-icon="mdi-volume-mute"
                                title="Completely muted."
                                text=""
                                >
                               </v-chip>
                            </div>
                            <div v-else>{{ item.silenced }}</div>
                        </template>
                        <template v-slot:[`item.tags`]="{ item }">
                            <div v-for="(tag_item, i) in item.tags" :key="i">
                              <v-chip
                                class="ttec_large_text"
                                density="compact"
                                color="#03A9F4"
                                variant="elevated"
                                :text="tag_item"
                              >
                              </v-chip>
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </div>
        <br/><br/>
    </div>
</template>

<script>
import axios from 'axios'

export default ({
  name: 'DatadogMonitor',
  components: {
  },
  data () {
    return {
      monitorEntries: [],
      headerMonitors: [],
      sortBy: 'start',
      sortDesc: true
    }
  },

  mounted () {
    var API_URL = 'http://xx.xx.xx.xx:xxxx'
    fetch('/config.json').then(res => res.json()).then(config => {
      API_URL = config['API_URL']
      axios.get(`${API_URL}/api/datadog/monitor`, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
        (payload) => {
          var retObj = payload.data
          this.monitorEntries = retObj.monitor_list
          this.headerMonitors = [
            { title: 'Priority', key: 'priority' },
            { title: 'Status', key: 'overall_state' },
            { title: 'Muted Left', key: 'silenced' },
            { title: 'Name', key: 'name' },
            { title: 'Tags', key: 'tags' }
          ]
        })
    })
  },
  beforeDestroy () {
  },
  created () {
  },
  methods: {
  }
})
</script>

<style scoped>
    .bg {
        width: 100%;
        height: 100%;
        /* position: absolute; */
        top: 0;
        left: 0;
        margin: 0px 0px;
        background: url( '../assets/img/pattern.jpg') repeat;
    }
    .ttec_contentWrapper
    {
        position:relative;
        width:92%;
        height:auto;
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-bottom:5px;
        margin-left: 15px;
    }
    .ttec_tenantWrapper
    {
        position:relative;
        /* height:auto; */
        width: 190px;
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-left: 3px;
        margin-top: 1px;
    }
    .ttec_content_activeConn_gray {
      padding:1% 1% 2% 2%;
      /* background-color: gray; */
    }
    .ttec_content
    {
        padding:1% 1% 2% 2%;
    }
    .ttec_content_selected
    {
        padding:1% 1% 2% 2%;
        border-style: solid;
        border-width: 2px;
        border-color: coral;
    }
    .ttec_content_activeConn
    {
        padding:1% 1% 2% 2%;
    }
    .text_green
    {
        color:#4CAF50;
    }
    .text_warning
    {
        color:#d9534f;
    }
    .ttec_large_text {
      font-size: 10px !important;
    }
    .ttec_bold_text {
      font-weight: bold !important;
    }
    .healthy {
        height: 20px;
        width: 20px;
        background-color: #99cc33;
        border-radius: 50%;
        display: inline-block;
        margin-left: 15px;
    }
    .warning {
        height: 20px;
        width: 20px;
        background-color: #ff9966;
        border-radius: 50%;
        display: inline-block;
        margin-left: 15px;
    }
    .critical {
        height: 20px;
        width: 20px;
        background-color: #cc3300;
        border-radius: 50%;
        display: inline-block;
        margin-left: 15px;
    }
    .cursor {
       cursor: pointer;
    }
    .container {
        display:flex;
        flex-flow:column wrap;
        max-height:290px;
    }
    a {
      text-decoration: none;
      text-transform: uppercase;
    }
    .chartRtrAgnet {
      margin-top: 5px;
      margin-left: 20px;
      margin-bottom: 5px;
    }
    .bg2 {
      padding-left: 0px;
      padding-right: 0px;
      background: url( '../assets/img/pattern.jpg') repeat;
    }
    .ttec_orange {
      background-color: #bcc;
      width:75%;
      padding-left: 8px;
      border-radius: 5px;
    }
    .ttec_green {
      background-color: #ddd;
      width:73%;
      margin-left: 10px;
      padding-left:8px;
      border-radius: 5px;
    }
    .v-data-table :deep(th) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: bold !important;
      background-color: #B0BEC5;
    }
    .v-data-table :deep(td) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: 300;
    }
    .monitor_ok {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #90EE90;
      font-weight:bold;
      background: #90EE90;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .monitor_warning {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #FFCC00;
      font-weight:bold;
      background: #FFCC00;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .monitor_alert {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #F32013;
      font-weight:bold;
      background: #F32013;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
</style>