<template>
  <div class="cui">
    <div class="card card--raised text-center">
        <div class="card__body mt-0 mb-0">
            <v-row no-gutters>
              <v-col cols="5">
                <div>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">Device Name:</div>
                        </v-col>
                        <v-col cols="6">  
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceName" @update:modelValue="deviceName = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">Description:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceDesc" @update:modelValue="deviceDesc = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">Type / SubType:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                              :items="deviceTypes"
                              item-title="type"
                              item-value="pkid"
                              hide-details
                              class="pb-1"
                              density="compact"
                              :modelValue="selectedDeviceType"
                              @update:modelValue="changeDeviceType"
                              >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP Addr1 Type:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceIpType1" @update:modelValue="deviceIpType1 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP1:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceIp1" @update:modelValue="deviceIp1 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP Addr2 Type:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceIpType2" @update:modelValue="deviceIpType2 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP2:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceIp2" @update:modelValue="deviceIp2 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP Addr3 Type:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceIpType3" @update:modelValue="deviceIpType3 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP3:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceIp3" @update:modelValue="deviceIp3 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP Addr4 Type:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceIpType4" @update:modelValue="deviceIpType4 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP4:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceIp4" @update:modelValue="deviceIp4 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">Serial Number:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceSerialNum" @update:modelValue="deviceSerialNum = $event"></v-text-field>
                        </v-col>
                  </v-row>
                  <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">Device Location:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact"  :modelValue="deviceLocation" @update:modelValue="deviceLocation = $event"></v-text-field>
                        </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="7">
                  <v-row no-gutters>
                      <v-col cols="2">
                        <div class="card__header half-margin-top">
                            <a class="text-large ma-0" href="javascript:;">{{ deviceName }}</a>
                            <div class="text-small ma-0">{{ deviceDesc }}</div>
                        </div>
                      </v-col>
                      <v-col cols="8">
                        <div class="pt-0 mt-0">
                            <img style="height:35px" src="img/lan-wireless.png">
                        </div>
                      </v-col>
                  </v-row>
                  <v-chip class="mt-0 ml-1 text-bold">Credential</v-chip>
                  <v-row no-gutters>
                        <v-col cols="8">
                          <v-row no-gutters>
                            <v-col cols="5">
                                <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">Credential - 1</v-chip>
                            </v-col>
                            <v-col cols="7">
                                <v-autocomplete
                                :items="custCreds"
                                item-title="user_pass"
                                item-value="pkid"
                                hide-details
                                density="compact"
                                :modelValue="selectedCred1"
                                @update:modelValue="changeCred1"
                                >
                                </v-autocomplete>
                            </v-col>
                           </v-row>
                        </v-col>
                        <v-col cols="4">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">C1 Usage</v-chip>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field class="pb-1" hide-details density="compact" placeholder="Enter..." :modelValue="cred1_usage" @update:modelValue="cred1_usage = $event"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                  </v-row>
                  <v-row no-gutters>
                      <v-col cols="8">
                          <v-row no-gutters>
                            <v-col cols="5">
                                <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">Credential - 2</v-chip>
                            </v-col>
                            <v-col cols="7">
                                <v-autocomplete
                                :items="custCreds"
                                item-title="user_pass"
                                item-value="pkid"
                                hide-details
                                density="compact"
                                :modelValue="selectedCred2"
                                @update:modelValue="changeCred2"
                                >
                                </v-autocomplete>
                            </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">C2 Usage</v-chip>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field class="pb-1" hide-details density="compact" placeholder="Enter..." :modelValue="cred2_usage" @update:modelValue="cred2_usage = $event"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                  </v-row>
                  <v-row no-gutters>
                      <v-col cols="8">
                          <v-row no-gutters>
                            <v-col cols="5">
                                <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">Credential - 3</v-chip>
                            </v-col>
                            <v-col cols="7">
                                <v-autocomplete
                                :items="custCreds"
                                item-title="user_pass"
                                item-value="pkid"
                                hide-details
                                density="compact"
                                :modelValue="selectedCred3"
                                @update:modelValue="changeCred3"
                                >
                                </v-autocomplete>
                            </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">C3 Usage</v-chip>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field class="pb-1" hide-details density="compact" placeholder="Enter..." :modelValue="cred3_usage" @update:modelValue="cred3_usage = $event"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                  </v-row>
                  <v-row no-gutters>
                      <v-col cols="8">
                          <v-row no-gutters>
                            <v-col cols="5">
                                <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">Credential - 4</v-chip>
                            </v-col>
                            <v-col cols="7">
                                <v-autocomplete
                                :items="custCreds"
                                item-title="user_pass"
                                item-value="pkid"
                                hide-details
                                density="compact"
                                :modelValue="selectedCred4"
                                @update:modelValue="changeCred4"
                                >
                                </v-autocomplete>
                            </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4">
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">C4 Usage</v-chip>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field class="pb-1" hide-details density="compact" placeholder="Enter..." :modelValue="cred4_usage" @update:modelValue="cred4_usage = $event"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                  </v-row>
                  <div>
                        <v-chip class="mt-5 mb-1 text-bold" size="small">Access Info</v-chip>
                        <Editor editorScript="nonce='FrFBJXRJMyWXjAjg4cUNZwEKktzfzD/YD9+S1kj2ors67hKoveam4aL0bZuCZU/jTiHTn0xDQGQh2ksCMXTXtA=='" v-model="dev_richTextContent" class="pl-2" editorStyle="height: 320px" />
                        <v-spacer></v-spacer>
                        HTML Coding: {{dev_richTextContent}}
                  </div>
              </v-col>
            </v-row>
        </div>
        <div>
            <v-spacer></v-spacer>
            <v-btn elevation="10" prepend-icon="mdi-content-save" class="ml-0 mt-0 mr-3" small dark color="ciscoblue" @click="addDevice">
                <template v-slot:prepend>
                    <v-icon color="success"></v-icon>
                </template>
                Add
            </v-btn>
            <v-btn elevation="10" prepend-icon="mdi-close-box" class="mt-0" small dark color="ciscoblue" @click="cancelAddDevice">
                <template v-slot:prepend>
                    <v-icon color="success"></v-icon>
                </template>
                Cancel
            </v-btn>
       </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils'
import { v4 as uuidv4 } from 'uuid'
import { useToast } from 'vue-toastification'

export default {
  name: 'DeviceAddDetail',
  props: {
    deviceCust: { type: String},
    deviceName: { type: String},
    deviceDesc: { type: String},
    deviceType: { type: String},
    deviceSerialNum: { type: String},
    deviceLocation: { type: String},
    deviceIp1: { type: String},
    deviceIpType1: { type: String},
    deviceSubType: { type: String},
    deviceIp2: { type: String},
    deviceIpType2: { type: String},
    deviceIp3: { type: String},
    deviceIpType3: { type: String},
    deviceIp4: { type: String},
    cred1_id: { type: String},
    cred1_usage: { type: String},
    cred2_id: { type: String},
    cred2_usage: { type: String},
    cred3_id: { type: String},
    cred3_usage: { type: String},
    cred4_id: { type: String},
    cred4_usage: { type: String},
    deviceIpType4: { type: String},
    deviceTypeId: { type: String},
    deviceTypes: { type: Array},
    custCreds: { type: Array}
  },
  data: () => ({
    show_password: false,
    dev_richTextContent: '',
    selectedDeviceType: '',
    selectedCred1: '',
    selectedCred2: '',
    selectedCred3: '',
    selectedCred4: '',
    credEntries: [],
    custCreds: []
  }),
  created () {
    // this.$store.dispatch('getDeviceCredList', { did: this.deviceCust })
  },

  methods: {
    toggleHide () {
      this.show_password = !this.show_password
    },
    addDevice () {
      var curDay = new Date()
      var devEntry = {
        'pkid': uuidv4(),
        'dev_cust': this.deviceCust,
        'dev_name': this.deviceName,
        'dev_type': this.selectedDeviceType,
        'dev_description': this.deviceDesc,
        'dev_ip1': this.deviceIp1,
        'dev_ipType1': this.deviceIpType1,
        'dev_ip2': this.deviceIp2,
        'dev_ipType2': this.deviceIpType2,
        'dev_ip3': this.deviceIp3,
        'dev_ipType3': this.deviceIpType3,
        'dev_ip4': this.deviceIp4,
        'dev_ipType4': this.deviceIpType4,
        'dev_credId1': this.selectedCred1,
        'dev_credUsage1': this.cred1_usage,
        'dev_credId2': this.selectedCred2,
        'dev_credUsage2': this.cred2_usage,
        'dev_credId3': this.selectedCred3,
        'dev_credUsage3': this.cred3_usage,
        'dev_credId4': this.selectedCred4,
        'dev_credUsage4': this.cred4_usage,
        'dev_sn': this.deviceSerialNum,
        'dev_location': this.deviceLocation,
        'dev_richTextContent': this.dev_richTextContent,
        'dev_update_by': this.$store.getters.getLoginUser,
        'time_stamp': curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0]
      }
      if (this.selectedDeviceType.trim() !== '' && this.deviceName.trim() !== '') {
        this.$store.dispatch('addDevice', { device: devEntry })
        // EventBus.$emit('closeDeviceAddDetail')
      } else {
        // alert('Please add device name and select device type for the device. Then try again.')
        const toast = useToast()
        toast.warning('Please add device name and select device type for the device. Then try again.')
      }
    },
    cancelAddDevice () {
      EventBus.emit('closeDeviceAddDetail')
    },
    changeDeviceType (value) {
      this.selectedDeviceType = value
    },
    changeCred1 (value) {
      this.selectedCred1 = value
    },
    changeCred2 (value) {
      this.selectedCred2 = value
    },
    changeCred3 (value) {
      this.selectedCred3 = value
    },
    changeCred4 (value) {
      this.selectedCred4 = value
    }
  },
  mounted () {
    this.show_password = false
    EventBus.on('addDevice_return', (payload) => {
      if (payload.status === 'OK') {
        EventBus.emit('addDevice_returnAdded')
      }
    })
  }
}
</script>

<style lang="css" scoped>
.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.v-text-field :deep(input) {
    font-size: 0.8em;
}

.v-text-field :deep(label) {
    font-size: 0.8em;
}

tbody :deep(tr:nth-of-type(even)) {
    background-color: rgba(236, 237, 237);
}

tbody :deep(tr:nth-of-type(odd)) {
    background-color: rgb(250 ,250, 250);
}

.v-data-table-header {
    background-color: rgba(182, 183, 187);
    color: white;
}
</style>
